import React from "react"

import Navigation from "../components/global/navigation"

import "./bootstrap.css"
import "./layout.css"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./global/footer"

const Layout = ({ children }) => {
  const layoutData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          navlinks {
            name
            id
            link
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Navigation data={layoutData.site.siteMetadata.navlinks} />
      <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
        {children}
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
