import React from "react"
import { Link } from "gatsby"

import ReydLogo from "../../images/reyd-logo.png"

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNavbarOpen: false,
      navbarStyle: "collapse navbar-collapse",
    }
  }

  handleMenuCollapse = () => {
    if (this.state.isNavbarOpen) {
      this.setState({
        isNavbarOpen: false,
        navbarStyle: "collapse navbar-collapse",
      })
    } else {
      this.setState({
        isNavbarOpen: true,
        navbarStyle: "collapse navbar-collapse show",
      })
    }
  }

  render() {
    console.log(this.props.data)
    return (
      <React.Fragment>
        <nav className="navbar shadow fixed-top navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <img style={{ height: "30px" }} src={ReydLogo} alt="Reyd Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.handleMenuCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={this.state.navbarStyle}>
            <ul className="navbar-nav ">
              {this.props.data.map(link => {
                return (
                  <li key={link.id} className="nav-item">
                    <Link
                      className="nav-link font-weight-bold text-uppercase small"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </React.Fragment>
    )
  }
}

export default Navigation
