import React from "react"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const AboutPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="About" />
      <Layout>
        <div className="py-4" />
        <div className="container-fluid my-4">
          <h1 className="display-4 font-weight-bold my-5 py-5">
            We are a company engaged in Muslim sports apparel.
          </h1>
          <div className="py-4" />
          <div className="row">
            <div className="col-12 col-md-6">
              <Img fluid={data.image1.childImageSharp.fluid} />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
          </div>
          <div className="py-4" />
          <div className="row py-5 my-5">
            <div className="col-12 col-md-6 text-muted lead">
              <p>
                The initial inspiration for this brand was the problem faced by
                Muslim women when exercising.
              </p>
              <p>
                Starting from Muslim women who do not exercise because of hijab,
                there are also those who complain when exercising because they
                wear hijab and some even remove their hijab while exercising in
                public places.
              </p>
              <p>
                This problem is what inspired us to become Muslim women’s sports
                clothing solutions.
              </p>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0 lead">
              <h5>OUR COMPANY VISION</h5>
              <p>
                “Leader in Muslim Sportswear industry both local and
                multinational.”
              </p>
              <h5>OUR MISSION</h5>
              <ol>
                <li>
                  Provide Muslimah Sportwear that prioritizes comfort and
                  fashionable product design
                </li>
                <li>
                  Focus on developing products in response to the needs of
                  sportswear for Muslim women
                </li>
              </ol>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default AboutPage

export const query = graphql`
  {
    image1: file(relativePath: { eq: "aboutPage/Ribbon-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "aboutPage/Basic-Gray-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
