import React from "react"
import { FaInstagram } from "react-icons/fa"

const Footer = () => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column align-items-center py-5 bg-light">
        <a
          className="text-muted"
          href="https://www.instagram.com/reyd_id/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <div className="p-2" />
        <p className="text-muted">
          <small>
            Copyright © {new Date().getFullYear().toString()} | REYD
          </small>
        </p>
        <p className="text-muted container text-center">
          <small>
            <i>
              Disclaimer: Sawir Studio has NEVER worked with this client. That
              means we have never work together to build this site. This site
              was built with purpose to show our future clients our ability to
              create beautiful website. If you're looking to buy their products,{" "}
              <a
                href="https://reyd.co.id"
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted font-weight-bold"
              >
                please visit their website here
              </a>
              .
            </i>
          </small>
        </p>
      </div>
    </React.Fragment>
  )
}

export default Footer
